<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="1200px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Export Default</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-stepper v-model="step">
                            <v-stepper-header>
                                <v-stepper-step :complete="step > 1" step="1">


                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step :complete="step > 2" step="2">

                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step :complete="step > 3" step="3">

                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step :complete="step > 4" step="4">

                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step :complete="step > 5" step="5">

                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step :complete="step > 6" step="6">

                                </v-stepper-step>
                            </v-stepper-header>

                            <v-stepper-items class="lfont">
                                <v-stepper-content step="1">
                                    <v-btn :loading="loading" :disabled="loading" color="primary"
                                        class="ma-2 white--text" @click="handle_step(2)">
                                        start Export
                                    </v-btn>
                                    <span v-if="tracking_export" class="text-danger">
                                        {{ tracking_export.current }}/{{ tracking_export.total }}
                                        ({{ tracking_export.percent }}%)</span>

                                </v-stepper-content>
                                <v-btn color="warning" class="ma-2 white--text" v-if="refresh"
                                    @click="submit_query_export()">
                                    <v-icon>mdi-refresh</v-icon>
                                </v-btn>
                            </v-stepper-items>
                        </v-stepper>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" :loading="loading" @click="submit_export_excel" class="text-white"
                        :disabled="step !== 7 || loading">
                        <v-icon>mdi-microsoft-excel</v-icon> Export
                    </v-btn>
                    <v-btn color="red darken-1" @click="$emit('action', false)" class="text-white" v-if="step === 1">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import io from "socket.io-client";
export default {
    components: {
    },
    props: {
        dialog: {
            default: false,
            type: Boolean
        },
        filters: {
            default: {},
            type: Object
        },

    },
    data() {
        return {
            step: 1,
            loading: false,
            parameter: null, // The parameter you are watching
            timeoutId: null, // To store the timeout ID
            refresh: false,
            tracking_export: null,
            socket: null
        }
    },
    methods: {
        async handle_step() {
            if (!this.filters.step)
                this.filters.step = 'info';
            await this.submit_query_export();
        },

        async submit_query_export() {
            this.loading = true;
            this.refresh = false;
            this.parameter = 'start';
            this.$axios.post(`export/clockin/employees/to/excel/query/v1`, this.filters)
                .then((res) => {
                    console.log(res.data);
                    if (res.data.code === 200) {
                        this.filters.tracking_id = res.data.data.tracking_id;
                    }
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        let obj = error.response.data.errors;
                        if (!obj) {
                            obj = error.response.data;
                            let res = "";
                            for (let [key, value] of Object.entries(obj)) {
                                res = value;
                            }
                            this.$notification.ShowErrors("top-right", "danger", 3000, res);
                        } else {
                            for (let [key, value] of Object.entries(obj)) {
                                this.server_errors[key] = value[0];
                                this.$notification.ShowErrors(
                                    "top-right",
                                    "danger",
                                    3000,
                                    (this.server_errors[key] = value[0])
                                );
                            }
                        }
                    }
                });
        },

        submit_export_excel() {
            this.loading = true;
            this.$axios.post(`export/clockin/employees/to/excel/v2`, this.filters)
                .then((res) => {
                    this.loading = false;
                    this.$router
                        .push({ name: "company.tracking-export-excel" })
                        .catch(() => { });
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.status === 422) {
                        let obj = error.response.data.errors;
                        if (!obj) {
                            obj = error.response.data;
                            let res = "";
                            for (let [key, value] of Object.entries(obj)) {
                                res = value;
                            }
                            this.$notification.ShowErrors("top-right", "danger", 3000, res);
                        } else {
                            for (let [key, value] of Object.entries(obj)) {
                                this.server_errors[key] = value[0];
                                this.$notification.ShowErrors(
                                    "top-right",
                                    "danger",
                                    3000,
                                    (this.server_errors[key] = value[0])
                                );
                            }
                        }
                    }
                });
        }
    },
    watch: {
        // Watch for changes to the parameter
        parameter() {
            // Clear any existing timeout
            clearTimeout(this.timeoutId);

            // Set a new timeout to trigger after 30 seconds
            this.timeoutId = setTimeout(() => {
                this.refresh = true;
                this.loading = false;
                this.parameter = null;
            }, 100000); // 30 seconds in milliseconds
        }
    },
    mounted: function () {
        const socket_url = process.env.VUE_APP_SOCKET_URL;
        this.socket = io(socket_url);
        this.socket.on("message", (res) => {
            console.log(res)
            if (res.tracking_id == this.filters.tracking_id) {
                if (res.export_step == 'info') {
                    this.step = 2;
                    this.filters.step = 'parts'
                } else if (res.export_step == 'parts') {
                    this.step = 3;
                    this.filters.step = 'clock_in_data'
                } else if (res.export_step == 'clock_in_data') {
                    this.step = 4;
                    this.filters.step = 'clock_date_leave'
                } else if (res.export_step == 'clock_date_leave') {
                    this.step = 5;
                    this.filters.step = 'leaves'
                } else if (res.export_step == 'leaves') {
                    this.step = 6;
                    this.filters.step = 'weeklyDayOff'
                } else if (res.export_step == 'weeklyDayOff') {
                    this.step = 7;
                }
                this.loading = false;
                this.parameter = res.export_step;

                if (this.step < 7) {
                    this.handle_step();
                }
            }
        });
    },
    destroyed() {
        // Clear the timeout when the component is destroyed
        clearTimeout(this.timeoutId);
    }
}
</script>